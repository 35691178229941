<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-row class="justify-content-center">
      <v-col :cols="deviceType == 'mobile' ? '12' : '9'">
        <v-card-title
          v-if="choose"
          style="background-color: transparent !important"
        >
          <h3>افزودن فرد جدید</h3></v-card-title
        >
        <v-card-title v-else style="background-color: transparent !important">
          <h3>افزودن {{ label }} جدید</h3></v-card-title
        >
        <v-card class="cards">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-select
                    @change="userData = {}"
                    placeholder="لطفا نقش مورد‌ نظر را انتخاب کنید"
                    v-if="choose"
                    outlined
                    dense
                    :items="types"
                    v-model="type"
                  ></v-select>
                </v-col>
              </v-row>
              <div v-if="type != ''">
                <v-row>
                  <v-col
                    v-if="
                      type == 'reception' ||
                      type == 'dentistryNurse' ||
                      type == 'dentistryAdmin' ||
                      type == 'admin'
                    "
                  >
                    <v-text-field
                      label="نام کاربری"
                      outlined
                      dense
                      v-model="userData.username"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="type == 'reception'">
                    <v-select
                      label="نوع پذیرش"
                      outlined
                      dense
                      :items="receptionTypes"
                      v-model="userData.receptionType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    type == 'reception' ||
                    type == 'dentistryNurse' ||
                    type == 'admin' ||
                    type == 'dentistryAdmin'
                  "
                >
                  <v-col class="pb-0" cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="رمز عبور"
                      v-model="userData.password"
                      type="password"
                      outlined
                      dense
                      autocomplete="new-password"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="تکرار رمز عبور"
                      v-model="userData.repeatPassword"
                      outlined
                      dense
                      type="password"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    (type == 'reception' || type == 'dentistryNurse') &&
                    userData.repeatPassword &&
                    userData.password &&
                    userData.password != userData.repeatPassword
                  "
                >
                  <v-col class="text-center text-danger pt-0 common-text">
                    تکرار رمز عبور وارد شده با رمز عبور همخوانی ندارد
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="نام و نام‌خانوادگی"
                      outlined
                      dense
                      v-model="userData.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="نام پدر"
                      outlined
                      dense
                      v-model="userData.fatherName"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div
                  v-if="type == 'doctor'"
                  class="text-right mb-2 common-text"
                >
                  لطفا تخصص پزشک را از بین گزینه‌ها یا بصورت دستی وارد کنید
                </div>
                <v-row>
                  <v-col
                    v-if="type == 'doctor'"
                    cols="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                  >
                    <v-combobox
                      v-model="userData.expertise"
                      :items="expertSuggests"
                      label="تخصص"
                      ref="expertise"
                      outlined
                      dense
                    ></v-combobox>
                  </v-col>
                  <v-col
                    v-if="
                      type == 'doctor' ||
                      type == 'nurse' ||
                      type == 'dentistryNurse'
                    "
                  >
                    <v-text-field
                      outlined
                      dense
                      label="شماره نظام"
                      v-model="userData.docCode"
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      v-model="userData.natCode"
                      label="کدملی"
                      type="number"
                      outlined
                      :rules="[isID]"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-select
                      label="جنسیت"
                      outlined
                      dense
                      v-model="userData.gender"
                      :items="genders"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="type == 'nurse' || type == 'dentistryNurse'">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-select
                      label="مدرک تحصیلی"
                      v-model="userData.education"
                      :items="education"
                      outlined
                      dense
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="مدرک"
                      outlined
                      dense
                      v-if="userData.education == 'سایر'"
                      v-model="userData.otherEducation"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="تحصیلات"
                      v-model="userData.education"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="bday">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="userData.BDay"
                        label=" تاریخ تولد "
                        :editable="true"
                        class="date-input"
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="userData.BDay"
                      element="bday"
                      color="#00a7b7"
                      type="date"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="type == 'nurse' || type == 'dentistryNurse'">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="bday2">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="userData.BDay"
                        label=" تاریخ تولد "
                        :editable="true"
                        class="date-input"
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="userData.BDay"
                      element="bday2"
                      color="#00a7b7"
                      type="date"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                      label="شماره موبایل"
                      outlined
                      dense
                      v-model="userData.mobile"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                      label="تلفن ثابت"
                      outlined
                      dense
                      v-model="userData.tel"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                      id="email"
                      label="ایمیل"
                      v-model="userData.email"
                      outlined
                      dense
                      :rules="[
                        validateEmail(userData.email)
                          ? true
                          : 'ایمیل وارد شده صحیح نمی‌باشد',
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row> </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      v-model="userData.state"
                      outlined
                      dense
                      label="استان"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      v-model="userData.city"
                      outlined
                      dense
                      label="شهر"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="userData.address"
                      label="آدرس دقیق"
                      outlined
                      dense
                      class="mt-2"
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="userData.canSendOut" color="primary">
                      <template v-slot:label>
                        <span class="mt-2">امکان امضای نامه برای این کاربر وجود دارد.</span>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col>
                    <v-btn
                      class="submit-btn primary-btn"
                      style="width: 15% !important"
                      @click="newPerson()"
                      >ثبت
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import md5 from "js-md5";
function checkCodeMeli(code) {
  //NOTE employee's checking validation of national code
  var L = code.length;

  if (L < 8 || parseInt(code, 10) == 0) return false;
  code = ("0000" + code).substr(L + 4 - 10);
  if (parseInt(code.substr(3, 6), 10) == 0) return false;
  var c = parseInt(code.substr(9, 1), 10);
  var s = 0;
  for (var i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);
  s = s % 11;
  return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
}

export default {
  props: ["job", "label"],
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      userData: {},
      genders: [
        { value: "زن", text: "زن" },
        { value: "مرد", text: "مرد" },
      ],
      types: [
        { value: "doctor", text: "پزشک" },
        { value: "nurse", text: "پرستار و پیراپزشک" },
        { value: "dentistryNurse", text: "دستیار دندانپزشک" },
        { value: "reception", text: "کارمند پذیرش" },
        { value: "servant", text: "کارکنان خدمات" },
        { value: "admin", text: "ادمین" },
        { value: "dentistryAdmin", text: "ادمین دندانپزشکی" },
      ],
      expertSuggests: ["عمومی", "دندانپزشک"],
      education: [
        { value: "کمک بهیار", text: "کمک بهیار" },
        { value: "بهیار", text: "بهیار" },
        { value: "تکنسین اتاق عمل", text: "تکنسین اتاق عمل" },
        { value: "فوریت های پزشکی", text: "فوریت های پزشکی" },
        { value: "کارشناس مامائی", text: "کارشناس مامائی" },
        { value: "کارشناس پرستاری", text: "کارشناس پرستاری" },
        { value: "کارشناس اتاق عمل", text: "کارشناس اتاق عمل" },
        { value: "کارشناس فیزیوتراپی", text: "کارشناس فیزیوتراپی" },
        { value: "کارشناس بینایی سنجی", text: "کارشناس بینایی سنجی" },
        { value: "کارشناس شنوایی سنجی", text: "کارشناس شنوایی سنجی" },
        { value: "کاردان علوم آزمایشگاهی", text: "کاردان علوم آزمایشگاهی" },
        { value: "کارشناس علوم آزمایشگاهی", text: "کارشناس علوم آزمایشگاهی" },
        { value: "تکنسین رادیولوژی", text: "تکنسین رادیولوژی" },
        { value: "کارشناش رادیولوژی", text: "کارشناش رادیولوژی " },
        { value: "سایر", text: "سایر" },
      ],
      receptionTypes: [
        { value: "reception", text: "پذیرش درمانگاه" },
        { value: "labReception", text: "پذیرش آزمایشگاه" },
      ],
      type: "",
      Busy: false,
      choose: true,
    };
  },

  methods: {
    validateEmail(email) {
      //NOTE checking validation of email
      if (email) {
        var re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      } else return true;
    },
    isID(id) {
      // NOTE (m-isID) to check the validity of the national code
      return id
        ? checkCodeMeli(id)
          ? true
          : "کدملی وارد شده صحیح نمی‌باشد"
        : true;
    },
    newPerson() {
      if (this.type == "doctor") {
        this.$refs["expertise"].blur();
      }
      setTimeout(() => {
        //NOTE adding new role to server
        let mdPass = "";
        let receptionType = "";
        if (
          this.type == "reception" ||
          this.type == "dentistryNurse" ||
          this.type == "dentistryAdmin" ||
          this.type == "admin"
        ) {
          mdPass = md5(this.userData.password);
          receptionType = this.userData.receptionType;
        }
        this.vLoading = true;
        this.$http
          .post(
            this.baseUrl + "/clinic/hr/employee/new",
            {
              role: this.type == "reception" ? receptionType : this.type,
              name: this.userData.name,
              mobile: this.userData.mobile,
              tel: this.userData.tel,
              expertise: this.userData.expertise ? this.userData.expertise : "",
              docCode: this.userData.docCode ? this.userData.docCode : "",
              email: this.userData.email,
              address: this.userData.address,
              gender: this.userData.gender,
              username: this.userData.username ? this.userData.username : "",
              password:
                this.userData.password == this.userData.repeatPassword
                  ? mdPass
                  : "",
              education: this.userData.education ? this.userData.education : "",
              natCode: this.userData.natCode,
              fatherName: this.userData.fatherName,
              city: this.userData.city,
              state: this.userData.state,
              BDay: this.userData.BDay,
              otherEducation: this.userData.otherEducation
                ? this.userData.otherEducation
                : "",
              canSendOut: this.userData.canSendOut,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              this.vLoading = false;
              this.toast(res.data, "success");
              this.$router.push("/admin/allEmployees");
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.vLoading = false;
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.vLoading = false;
          });
      }, 500);
    },
  },

  mounted() {
    if (this.job) {
      this.choose = false;
      this.type = this.job;
    } else {
      this.choose = true;
    }
  },
};
</script>
